<template>
  <div>
    <PartnerFilterBar
      ref="filterBar"
      :isShowStatus="false"
      :keywordDatas="keywordDatas"
      :defaultKeywordSelectedId="defaultKeywordSelectedId"
      :defaultKeywordInputText="defaultKeywordInputText"
      :statusList="statusList"
      :defaultStateId="defaultStateId"
      @onClickSearch="onClickSearch"
      @onClickExcelBtn="onClickExcelBtn"
    />

    <TableHead>
      <template v-slot:headRight>
        <SelectBoxPageRange
          :currentMaxRowCount.sync="size"
          @update:currentMaxRowCount="onChangeRowCount"
        />
      </template>
    </TableHead>
    <ListPage
      :list="dataList"
      :currentPageIndex="currentPageIndex"
      :totalPageCount="totalPageCount"
      :maxRowCount="size"
      :maxPaginationCount="maxPaginationCount"
      @onChangePage="onChangePage"
    >
      <template v-slot:colgroup>
        <col style="width: 170px" />
        <!-- 상호 -->
        <col style="width: 102px" />
        <!-- 사업자 번호 -->
        <col style="width: 102px" />
        <!-- 신용등급 -->
        <col style="width: 100px" />
        <!-- 대표자명 -->
        <col style="width: 110px" />
        <!-- 대표 연락처 -->
        <col style="width: 118px" />
        <!-- 신용평가등급(점수) -->
        <!-- <col v-if="isCreditAutoSync" style="width: 90px" />-->
        <col style="width: 90px" />
        <!-- 파트너 신청일 -->
        <col style="width: 90px" />
        <!-- 상태 변경일 -->
        <col style="width: 108px" />
        <!-- 상태 -->
      </template>
      <template v-slot:tr>
        <th>상호</th>
        <th>사업자 번호</th>
        <th>신용등급</th>
        <th>대표자명</th>
        <th>대표 연락처</th>

        <th>평가 점수</th>
        <th>파트너 신청일</th>
        <th>상태 변경일</th>
        <th>상태</th>
      </template>
      <template v-slot:row="slotProps">
        <PartnerListPresentLine
          :rowData="slotProps.rowData"
          :statusList="listLineStatusList"
          :isCanEdit="isCanEdit"
          @onChangeStatus="onChangeStatus"
        />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="9" class="td_empty">파트너가 없습니다</td>
        </tr>
      </template>
    </ListPage>
  </div>
</template>

<script>
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import PartnerFilterBar from "@/components/admin/partner/list/PartnerFilterBar";
import PartnerListPresentLine from "./PartnerListPresentLine";

import { mapState } from "vuex";
import ApiService from "@/services/ApiService";
import { GET_PARTNER_STATUS_LIST } from "@/store/modules/partner/action";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { downloadFile } from "@/utils/fileUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import ListPageMixin from "@/mixins/ListPageMixin";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  components: {
    ListPage,
    TableHead,
    SelectBoxPageRange,
    PartnerFilterBar,
    PartnerListPresentLine,
  },
  mixins: [ListPageMixin],
  props: {
    keywordDatas: Array,
    isCanEdit: Boolean,
  },
  data() {
    return {
      API_PATH: this.$apiPath.PARTNER,

      defaultKeywordSelectedId: "companyName",

      defaultStateId: "0",
      listLineStatusList: [
        { code: "C", name: "승인" },
        { code: "N", name: "검토" },
        { code: "R", name: "거부" },
        { code: "P", name: "대기", isDisabled: true },
        { code: "D", name: "휴면", isDisabled: true }, // 없어야할 것 같지만, 기존에 노출 되고 있었기 때문에 그냥 넣기로.
      ],
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_PARTNER_STATUS_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.partner.statusList,
    }),
    isCreditAutoSync() {
      return LocalStorageManager.shared.getIsCreditAutoSync();
    },
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordDatas);

      const objStatus = this.checkQueryStatus(query);

      return Object.assign({}, objPaging, objKeyword, objStatus, {
        all: "n",
        confirm: "n",
      });
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (status) {
        params.status = status;
      }

      params.all = "n";
      params.confirm = "n";

      //params.tab = 'present';

      return params;
    },
    onChangeStatus(to, from, rowData) {
      this.$emit("onChangeStatus", to, from, rowData);
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/partner`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) {
        this.$store.dispatch(PROGRESS_END_ACTION);
        return;
      }
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `협력사관리_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>
