<template>
  <FilterBarBase
    :excelBtn="$isAdmin ? true : false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl v-if="!isShowStatus" class="w152">
      <dt>상태</dt>
      <dd>
        <SelectBox class="w152" :dataList="statusList" :value.sync="stateId" />
      </dd>
    </dl>
    <dl>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <Radio
            name="servicePartnerListRadio0"
            :dataList="keywordDatas"
            :selectedId.sync="keywordSelectedId"
          />
          <!-- class="w494" -->
          <Input
            class="w380"
            type="text"
            placeholder="검색어를 입력하세요."
            :value.sync="keywordInputText"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  // data() {
  //   return{
  //     placeHolderText : '상호명을 입력하세요.'
  //   }
  // },
  // computed: {
  //   placeHolderText(){
  //     //if( this.keywordSelectedId == )
  //   }
  // },
  components: {
    FilterBarBase,
    SelectBox,
    Radio,
    Input,
  },
  mixins: [FilterBarMixin],
  props: {
    isShowStatus: Boolean,
    isShowExcelBtn: Boolean,
    defaultStateId: String,
    defaultKeywordSelectedId: String,
    dateDatas: Array,
    defaultDateSelectedId: String,
  },
  data() {
    return {
      stateId: this.defaultStateId,
      keywordSelectedId: this.defaultKeywordSelectedId,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit("onClickExcelBtn", isDatesSelected);
    },
    onClickReset() {
      this.onClickResetFilterBar();
      this.stateId = "0";
    },
  },
};
</script>
