<template>
  <ListRow>
    <td>
      <router-link :to="toPath" class="link_subject align_center" v-html="rowData.companyName" />
    </td>
    <td>{{ rowData.corporateNum }}</td>
    <td class="td_ellip align_left" :data-ellip="addressTextFull">
      {{ addressTextFull }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.email">
      {{ rowData.email }}
    </td>
    <td>{{ rowData.tel }}</td>
    <td class="td_ellip" :data-ellip="rowData.ceo">
      {{ rowData.ceo }}
    </td>
    <td class="td_ellip" :data-ellip="rowData.name">
      {{ rowData.name }}
    </td>
    <td>{{ rowData.companyBuildDate | dateStringFormat }}</td>

    <td v-if="isCreditAutoSync">
      {{ rowCreditScore }}
    </td>
    <td>{{ rowData.companyType }}</td>
    <td>{{ rowData.cashGrade }}</td>

    <td>{{ rowData.salesAmount }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "PartnerListApprovalLine",
  components: {
    ListRow,
    SelectBox,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { userNum } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.PARTNER_VIEW, userNum, this.$route.query);
      return path;
    },
    isCreditAutoSync() {
      return LocalStorageManager.shared.getIsCreditAutoSync();
    },

    rowCreditScore() {
      let { creditGrade, score } = this.rowData;
      if (!creditGrade || creditGrade == "") {
        return "-";
      }
      if (!score || score == "" || score == 0) {
        return "-";
      }
      const gradeScore = creditGrade + "(" + score + ")";
      return gradeScore;
    },
  },
};
</script>
