<template>
  <Popup popupType="tableType" width="824px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">검토 의견</h3>
        <p class="desc_tip">
          <span class="ico_purchase ico_exclam" />파트너사에게 발송할 회원가입 정보 보완 의견을
          작성해주세요.
        </p>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 608px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>검토 의견</th>
              <td class="h96">
                <Textarea :value.sync="text" placeholder="" :isFocusOnMount="true" />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button type="submit" class="btn_primary btn_medium" @click="onClickSubmit">확인</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import Textarea from "@/components/common/textarea/Textarea";

export default {
  name: "ReviewPopup",
  components: {
    Popup,
    TableView,
    Textarea,
  },
  props: {
    reviewOpinion: String,
  },
  data() {
    return {
      text: "",
    };
  },
  beforeMount() {
    if (this.reviewOpinion) {
      this.text = this.reviewOpinion;
    }
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSubmit() {
      this.$emit("onClickSubmit", this.text);
    },
  },
};
</script>
