<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">협력사 관리</h2>
      </div>
      <div class="body_section">
        <PartnerListPresent
          :isCanEdit="isCanEdit"
          :keywordDatas="KEYWORD_DATAS"
          @onChangeStatus="onChangePartnerStatus"
        />
      </div>
    </div>
    <template v-slot:popup>
      <AlertPopup
        v-if="isPopChangeStatus"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        alertText="파트너 상태를 변경하시겠습니까?<br>변경한 상태에 대한 메일이 발송됩니다."
        @onClickFourtyly="onClickCancelPartnerStatus"
        @onClickPrimary="onClickChangePartnerStatus"
      />
      <ReviewPopup
        v-if="isPopReview"
        @onClickClose="onClickClosePopReview"
        @onClickSubmit="onClickSubmitPopReview"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Tabs from "@/components/common/tabs/Tabs";

import PartnerListAll from "@/components/admin/partner/list/PartnerListAll";
import PartnerListApproval from "@/components/admin/partner/list/PartnerListApproval";
import PartnerListPresent from "@/components/admin/partner/list/PartnerListPresent";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ReviewPopup from "@/components/admin/partner/popup/ReviewPopup";

import { board } from "@/constants/options";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "PartnerList",
  components: {
    PageWithLayout,
    Tabs,
    PartnerListAll,
    PartnerListApproval,
    PartnerListPresent,
    AlertPopup,
    ReviewPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      KEYWORD_DATAS: [
        { id: "companyName", text: "상호" },
        { id: "corporateNum", text: "사업자 번호" },
      ],

      // KEY_PARAM: 'tab',
      // TAB_DATA_LIST: [
      //   { value: 'present', text: '파트너 현황' },
      //    { value: 'approved', text: '승인된 파트너' },
      // ],
      //currentTabValue: 'present',

      isPopChangeStatus: false,
      isPopReview: false,

      selectBoxPrev: "", // alert 띄우고 취소하면 이전 값으로 돌아가야 함.
      rowDataSelected: null,
    };
  },
  computed: {
    isCanEdit() {
      return (
        !LocalStorageManager.shared.getIsInspect() && !LocalStorageManager.shared.getIsRollUser()
      );
    },
  },
  methods: {
    // 파트너 현황 - 상태 변경 ==============================
    onChangePartnerStatus(to, from, rowData) {
      this.selectBoxPrev = from;
      this.rowDataSelected = rowData;

      if (to === "C") {
        if (rowData.aprvlInfo.docStatus !== "C") {
          this.alert("전자결재를 진행하여 주세요");
          this.resetStatus();
          return;
        }
      }

      if (this.rowDataSelected.status === "N") {
        // 검토
        this.isPopReview = true;
      } else {
        this.isPopChangeStatus = true;
      }
    },
    onClickChangePartnerStatus() {
      this.isPopChangeStatus = false;

      const obj = this.getObjToSend();
      this.changePartnerStatus(obj);
    },
    onClickSubmitPopReview(text) {
      this.isPopReview = false;

      const obj = this.getObjToSend();
      obj.review = text;

      this.changePartnerStatus(obj);
    },
    async paymentPartnerJoin() {
      //전자결재 여부 API
    },
    async changePartnerStatus(obj) {
      const url = `${this.$apiPath.PARTNER}/${this.rowDataSelected.userNum}/status/${this.rowDataSelected.status}`;

      const result = await ApiService.shared.putData(url, obj);
      if (result.code !== "200") {
        this.alert(result.text);

        this.resetStatus();

        return;
      }

      this.alert("상태가 변경되었습니다.");
    },
    getObjToSend() {
      let obj = {};

      const userData = LocalStorageManager.shared.getUserData();
      if (!userData) return obj;

      const { managerNum } = userData;
      if (managerNum !== 0 && !managerNum) return obj;

      obj.managerNum = managerNum;

      // review 가 필요한 곳에서는 추가할 것.

      return obj;
    },
    onClickCancelPartnerStatus() {
      this.resetStatus();
    },
    onClickClosePopReview() {
      this.resetStatus();
    },
    resetStatus() {
      // 이전 상태 값으로 돌려준다.
      this.rowDataSelected.status = this.selectBoxPrev;

      this.isPopChangeStatus = false;
      this.isPopReview = false;
    },
  },
};
</script>
