<template>
  <div>
    <PartnerFilterBar
      :isShowStatus="false"
      :keywordDatas="keywordDatas"
      :defaultKeywordSelectedId="defaultKeywordSelectedId"
      :defaultKeywordInputText="defaultKeywordInputText"
      :statusList="statusList"
      :defaultStateId="defaultStateId"
      @onClickSearch="onClickSearch"
    />

    <TableHead>
      <template v-slot:headLeft>
        <button type="button" class="btn_tertiary btn_medium" @click="onClickRequest">
          파트너 신청
        </button>
        <p class="desc_tip tc_red">
          <span class="ico_purchase ico_exclam_r" />업체를 선택하여 '파트너 신청' 버튼을 대표
          이메일로 파트너 신청 메일이 전송됩니다.
        </p>
      </template>
      <template v-slot:headRight>
        <SelectBoxPageRange
          :currentMaxRowCount.sync="size"
          @update:currentMaxRowCount="onChangeRowCount"
        />
      </template>
    </TableHead>
    <ListPage
      :list="dataList"
      :currentPageIndex="currentPageIndex"
      :totalPageCount="totalPageCount"
      :maxRowCount="size"
      :maxPaginationCount="maxPaginationCount"
      @onChangePage="onChangePage"
    >
      <template v-slot:colgroup>
        <col style="width: 60px" />
        <!-- checkbox -->
        <col style="width: 120px" />
        <!-- 상호 -->
        <col style="width: 110px" />
        <!-- 사업자 번호 -->
        <col style="width: 300px" />
        <!-- 주소 -->
        <col style="width: 225px" />
        <!-- 대표 이메일 -->
        <col style="width: 105px" />
        <!-- 대표 연락처 -->
        <col style="width: 110px" />
        <!-- 업태 -->
        <col style="width: 110px" />
        <!-- 업종 -->
        <col style="width: 100px" />
        <!-- 상태 -->
      </template>
      <template v-slot:tr>
        <th>
          <CheckboxItem @update:checkedNames="onClickedAll" />
        </th>
        <th>상호</th>
        <th>사업자 번호</th>
        <th>주소</th>
        <th>대표 이메일</th>
        <th>대표 연락처</th>
        <th>업태</th>
        <th>업종</th>
        <th>상태</th>
      </template>
      <template v-slot:row="slotProps">
        <PartnerListAllLine :rowData="slotProps.rowData" :checkedNames.sync="checkedNames" />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="9" class="td_empty">파트너가 없습니다</td>
        </tr>
      </template>
    </ListPage>
  </div>
</template>

<script>
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import PartnerFilterBar from "@/components/admin/partner/list/PartnerFilterBar";
import PartnerListAllLine from "./PartnerListAllLine";

import { mapState } from "vuex";
import { GET_MANAGER_STATUS_LIST } from "@/store/modules/manager/action";

import ListPageMixin from "@/mixins/ListPageMixin";

export default {
  components: {
    ListPage,
    TableHead,
    CheckboxItem,
    SelectBoxPageRange,
    PartnerFilterBar,
    PartnerListAllLine,
  },
  mixins: [ListPageMixin],
  props: {
    keywordDatas: Array,
  },
  data() {
    return {
      API_PATH: this.$apiPath.PARTNER,

      defaultKeywordSelectedId: "companyName",

      checkedNames: [],
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_MANAGER_STATUS_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.manager.statusList,
    }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordDatas);

      const objStatus = this.checkQueryStatus(query);

      return Object.assign({}, objPaging, objKeyword, objStatus, {
        all: "y",
        confirm: "n",
      });
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (status) {
        params.status = status;
      }

      params.all = "y";
      params.confirm = "n";

      params.tab = "all";

      return params;
    },
    getCheckedDataList() {
      const arrId = this.checkedNames;
      if (arrId.length < 1) return null;

      const arrChecked = this.dataList.filter((item) => {
        // return arrId.some( userNum => userNum === item.userNum );
        return arrId.some((corporateNum) => corporateNum === item.corporateNum);
      });

      return arrChecked;
    },
    onClickRequest() {
      const arrChecked = this.getCheckedDataList();

      this.$emit("onClickRequest", arrChecked);
    },
    onClickedAll(bSelect) {
      if (bSelect) {
        this.checkedNames = this.dataList.map((item) => item.corporateNum);
      } else {
        this.checkedNames = [];
      }
    },
  },
};
</script>
