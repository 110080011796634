<template>
  <ListRow>
    <td>
      <CheckboxItem :id="rowData.corporateNum" :checkedNames.sync="checkedNamesSync" />
    </td>
    <td class="td_ellip" :data-ellip="rowData.companyName" v-html="rowData.companyName">
      <!-- <router-link :to='toPath' :title="rowData.companyName" class="link_subject align_center"> -->
      <!-- </router-link> -->
    </td>
    <td>{{ rowData.corporateNum }}</td>
    <td class="align_left">
      {{ addressTextFull }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.email">
      {{ rowData.email }}
    </td>
    <td>{{ rowData.tel }}</td>
    <td>{{ rowData.business }}</td>
    <td>{{ rowData.businessType }}</td>
    <td>{{ rowData.statusName }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "PartnerListLine",
  components: {
    ListRow,
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    checkedNames: Array,
  },
  computed: {
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
};
</script>
