<template>
  <ListRow>
    <td>
      <router-link :to="toPath" class="link_subject align_center" v-html="rowData.companyName" />
    </td>
    <td>{{ rowData.corporateNum }}</td>
    <td>
      <template v-if="rowData.creditGrade">
        <a :href="computedCreditGradeLink" class="link_subject align_center" target="_blank">{{
          rowData.creditGrade | nullToDash
        }}</a>
      </template>
      <template v-else>
        {{ "-" }}
      </template>
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.email">
      {{ rowData.ceo }}
    </td>
    <td>{{ rowData.tel }}</td>

    <td>{{ rowData.totalScore }}</td>
    <td>{{ regDate }}</td>
    <td>{{ statusDate }}</td>
    <td>
      <SelectBox
        v-if="isCanEdit"
        class="w72"
        :dataList="statusList"
        :value.sync="rowData.status"
        @onChange="onChangeStatus"
      />
      <span v-else>{{ rowData.statusName }}</span>
    </td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "PartnerListPresentLine",
  components: {
    ListRow,
    SelectBox,
  },
  mixins: [ListLineMixin],
  props: {
    statusList: Array,
    isCanEdit: Boolean,
  },
  computed: {
    toPath() {
      const { userNum } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.PARTNER_VIEW, userNum, this.$route.query);

      return path;
    },
    isCreditAutoSync() {
      return LocalStorageManager.shared.getIsCreditAutoSync();
    },

    rowCreditScore() {
      let { creditGrade, score } = this.rowData;
      if (!creditGrade || creditGrade == "") {
        return "-";
      }
      if (!score || score == "" || score == 0) {
        return "-";
      }
      const gradeScore = creditGrade + "(" + score + ")";
      return gradeScore;
    },
    computedCreditGradeLink() {
      const link = `http://clip.nice.co.kr/rep_nclip/rep_DLink/rep_Link_ligs.jsp?bz_ins_no=`;
      const corporateNum = this.rowData.corporateNum.split("-").join("");
      return `${link}${corporateNum}`;
    },
  },
  methods: {
    onChangeStatus(to, from) {
      this.$emit("onChangeStatus", to, from, this.rowData);
    },
  },
};
</script>
