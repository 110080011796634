<template>
  <div>
    <PartnerFilterBar
      ref="filterBar"
      :isShowStatus="true"
      :isShowExcelBtn="true"
      :keywordDatas="keywordDatas"
      :defaultKeywordSelectedId="defaultKeywordSelectedId"
      :defaultKeywordInputText="defaultKeywordInputText"
      @onClickSearch="onClickSearch"
      @onClickExcelBtn="onClickExcelBtn"
    />

    <TableHead>
      <template v-slot:headRight>
        <SelectBoxPageRange
          :currentMaxRowCount.sync="size"
          @update:currentMaxRowCount="onChangeRowCount"
        />
      </template>
    </TableHead>
    <ListPage
      :list="dataList"
      :currentPageIndex="currentPageIndex"
      :totalPageCount="totalPageCount"
      :maxRowCount="size"
      :maxPaginationCount="maxPaginationCount"
      @onChangePage="onChangePage"
    >
      <template v-if="isCreditAutoSync" v-slot:colgroup>
        <col style="width: 170px" />
        <!-- 상호 -->
        <col style="width: 100px" />
        <!-- 사업자 번호 -->
        <col style="width: 125px" />
        <!-- 주소 -->
        <col style="width: 120px" />
        <!-- 대표 이메일 -->
        <col style="width: 107px" />
        <!-- 대표 연락처 -->
        <col style="width: 80px" />
        <!-- 대표이사명 -->
        <col style="width: 70px" />
        <!-- 담당자명 -->
        <col style="width: 90px" />
        <!-- 설립일 -->
        <col style="width: 120px" />
        <!-- 신용평가등급(점수) -->
        <col style="width: 71px" />
        <!-- 기업규모 -->
        <col style="width: 91px" />
        <!-- 현금흐름등급 -->
        <col style="width: 96px" />
        <!-- 연매출액 -->
      </template>
      <template v-else v-slot:colgroup>
        <col style="width: 188px" />
        <!-- 상호 -->
        <col style="width: 100px" />
        <!-- 사업자 번호 -->
        <col style="width: 180px" />
        <!-- 주소 -->
        <col style="width: 160px" />
        <!-- 대표 이메일 -->
        <col style="width: 110px" />
        <!-- 대표 연락처 -->
        <col style="width: 80px" />
        <!-- 대표이사명 -->
        <col style="width: 70px" />
        <!-- 담당자명 -->
        <col style="width: 90px" />
        <!-- 설립일 -->
        <col style="width: 71px" />
        <!-- 기업규모 -->
        <col style="width: 91px" />
        <!-- 현금흐름등급 -->
        <col style="width: 100px" />
        <!-- 연매출액 -->
      </template>
      <template v-slot:tr>
        <th>상호</th>
        <th>사업자 번호</th>
        <th>주소</th>
        <th>대표 이메일</th>
        <th>대표 연락처</th>
        <th>대표이사명</th>
        <th>담당자명</th>
        <th>설립일</th>

        <th v-if="isCreditAutoSync">신용평가등급(점수)</th>

        <th>기업규모</th>
        <th>현금흐름등급</th>
        <th>연매출액</th>
      </template>
      <template v-slot:row="slotProps">
        <PartnerListApprovalLine :rowData="slotProps.rowData" />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td :colspan="isCreditAutoSync ? 12 : 11" class="td_empty">승인된 파트너가 없습니다</td>
        </tr>
      </template>
    </ListPage>
    <!-- <AlertPopup
      @onClickFourtyly='onClickCancelAlert'
      @onClickPrimary='onClickConfirmAlert'
      v-if='isPopAlert'
      alertText="기간을 설정하지 않으셨습니다.<br>모든 데이터를 다운로드 하시겠습니까?"
      btnFourthlyText="취소"
      btnPrimaryText="확인" /> -->
    <ExcelDonwloadPopup
      v-if="isPopExcel"
      :excelDataList="excelHeaderList"
      :checkedNames.sync="checkedNamesExcel"
      @onClickClose="onClickCloseExcel"
      @onClickDownload="onClickDownloadExcel"
    />
  </div>
</template>

<script>
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
// import AlertPopup from '@/components/layout/popup/AlertPopup';
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";

import PartnerFilterBar from "@/components/admin/partner/list/PartnerFilterBar";
import PartnerListApprovalLine from "./PartnerListApprovalLine";

import ListPageMixin from "@/mixins/ListPageMixin";
import LocalStorageManager from "@/LocalStorageManager";

import excelKey from "@/constants/excelKey";

export default {
  components: {
    ListPage,
    TableHead,
    SelectBoxPageRange,
    // AlertPopup,
    ExcelDonwloadPopup,
    PartnerFilterBar,
    PartnerListApprovalLine,
  },
  mixins: [ListPageMixin],
  props: {
    keywordDatas: Array,
  },
  data() {
    return {
      API_PATH: this.$apiPath.PARTNER,

      defaultKeywordSelectedId: "companyName",

      excelKey: excelKey.APPROVED_PARTNER,

      hasDate: false,
    };
  },
  computed: {
    isCreditAutoSync() {
      return LocalStorageManager.shared.getIsCreditAutoSync();
    },
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.getDataInit(params);
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordDatas);

      return Object.assign({}, objPaging, objKeyword, {
        all: "n",
        confirm: "y",
      });
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      params.all = "n";
      params.confirm = "y";

      params.tab = "approved";

      return params;
    },
  },
};
</script>
